module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"penhersky","short_name":"penhersky","start_url":"/","background_color":"#000","theme_color":"rgba(108, 108, 108, 0.381)","display":"minimal-ui","icon":"src//static/icons/app/48.png","icons":[{"src":"/static/icons/app/48.png","sizes":"48x48","type":"image/png"},{"src":"/static/icons/app/72.png","sizes":"72x72","type":"image/png"},{"src":"/static/icons/app/96.png","sizes":"96x96","type":"image/png"},{"src":"/static/icons/app/144.png","sizes":"144x144","type":"image/png"},{"src":"/static/icons/app/192.png","sizes":"192x192","type":"image/png"},{"src":"/static/icons/app/310.png","sizes":"310x310","type":"image/png"},{"src":"/static/icons/app/512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42cb46f25e77f806491dab3fabc49008"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
